import React from 'react';

import SignupBar from './SignupBar/SignupBar';
import HowItWorks from './HowItWorks';
import MakesCarousel from './MakesCarousel';
import Masthead from './Masthead';
import NewTestimonials from './NewTestimonials';

const HomePage: React.FC = () => {
	return (
		<>
			<Masthead />

			<MakesCarousel />

			<NewTestimonials />

			<HowItWorks />

			<SignupBar />
		</>
	);
};

export default HomePage;
