import React from 'react';
import { MDText } from 'i18n-react';

import { Hyperlink } from '@motorway/mw-highway-code/alpha';

import {
	triggerMotorwayMoveLinkClicked,
	triggerMotorwayPayLinkClicked,
} from 'Services/analytics/events/homePageEvents';
import Content from 'Storybook/local/Content/Content';

import ImageLazy from '../ImageLazy/ImageLazy';
import texts from '../texts.json';

import newStep1Png from './images/how_it_works_step_1.jpg';
import newStep1Webp from './images/how_it_works_step_1.webp';
import newStep2Png from './images/how_it_works_step_2_new.jpg';
import newStep2Webp from './images/how_it_works_step_2_new.webp';
import newStep3Png from './images/how_it_works_step_3_new.jpg';
import newStep3Webp from './images/how_it_works_step_3_new.webp';
import newStep4Png from './images/how_it_works_step_4_new.jpg';
import newStep4Webp from './images/how_it_works_step_4_new.webp';
import newStep5Png from './images/how_it_works_step_5_new.jpg';
import newStep5Webp from './images/how_it_works_step_5_new.webp';
import type { ImagePair } from './HowItWorks.types';

import styles from './HowItWorks.module.scss';

const newImagesByStep: ImagePair[] = [
	[newStep1Png, newStep1Webp],
	[newStep2Png, newStep2Webp],
	[newStep3Png, newStep3Webp],
	[newStep4Png, newStep4Webp],
	[newStep5Png, newStep5Webp],
];

const T = new MDText(texts);

export const StepRender = ([pngSource, webpSource]: ImagePair, index: number): React.ReactElement => {
	const stepIndex = index + 1;

	return (
		<div key={`step${stepIndex}`} className={styles.step}>
			<div className={styles.content}>
				<span className={styles.stepCounter}>Step 0{stepIndex}</span>
				<h3>{T.translate(`homePage.howItWorks.step${stepIndex}.newTitle`)}</h3>
				<p>
					{T.translate(`homePage.howItWorks.step${stepIndex}.newDescription`, {
						motorwayMove: (
							<Hyperlink
								as="a"
								href="/motorway-move"
								label={T.translate('homePage.hero.usp.point4.motorwayMove')}
								onClick={() => triggerMotorwayMoveLinkClicked('page_bottom')}
								size="large"
								target="_blank"
							/>
						),
						motorwayPay: (
							<Hyperlink
								as="a"
								href="/motorway-pay"
								label={T.translate('homePage.hero.usp.point4.motorwayPay')}
								onClick={() => triggerMotorwayPayLinkClicked('page_bottom')}
								size="large"
								target="_blank"
							/>
						),
					})}
				</p>
			</div>
			<div className={styles.image}>
				<ImageLazy altText={`Step ${stepIndex}`} pngSource={pngSource} webpSource={webpSource} />
			</div>
		</div>
	);
};

const HowItWorks: React.FC = () => {
	return (
		<>
			<div className={styles.anchorLink} id="howitworks" rel="help" />
			<Content className={styles.section} element="section" wrapper={{ className: styles.sectionWrapper }}>
				<h2>
					<strong>Thousands</strong> of cars, one <strong>easy-to-use</strong> platform, <strong>zero hassle</strong>.
					Here’s how it works.
				</h2>
				<div className={styles.stepsWrapper}>{newImagesByStep.map(StepRender)}</div>
			</Content>
		</>
	);
};

export default HowItWorks;
