import React from 'react';
import T from 'i18n-react';
import Link from 'next/link';

import { Button } from '@motorway/mw-highway-code';

import { triggerBrowseVehiclesButtonClicked, triggerSignUpNowLinkClicked } from 'Services/analytics/events';
import useIsAuth from 'Utilities/hooks/useIsAuth';
import { useVehicleListButtonConfigWithUnauthedFallback } from 'Utilities/hooks/vehicleList/useVehicleListButtonConfig';

import texts from '../../texts.json';
import backgroundAvif from '../images/home-signup-bar.avif';
import backgroundJpg from '../images/home-signup-bar.jpg';
import backgroundWebp from '../images/home-signup-bar.webp';

import styles from './SignupBar.module.scss';

T.setTexts(texts);

const SignupBar: React.FC = () => {
	const isAuth = useIsAuth();
	const { as, href } = useVehicleListButtonConfigWithUnauthedFallback({ unauthorizedFallback: 'signUp' });

	return (
		<section className={styles.signupBarWrapper}>
			<div className={styles.signupBar}>
				<div className={styles.content}>
					<h2 className={styles.title}>
						{T.translate('homePage.signupBar.title', {
							strong: <strong>{T.translate('homePage.signupBar.strong')}</strong>,
						})}
					</h2>
					<Link legacyBehavior passHref as={as} href={href}>
						<Button
							label={T.translate(`homePage.signupBar.buttonLabel${isAuth ? ':signin' : ''}`)}
							onClick={() =>
								isAuth ? triggerBrowseVehiclesButtonClicked('page_bottom') : triggerSignUpNowLinkClicked('page_bottom')
							}
						/>
					</Link>
				</div>
				<div className={styles.image}>
					<picture>
						<source srcSet={backgroundWebp.src} type="image/webp" />
						<source srcSet={backgroundAvif.src} type="image/avif" />
						<img alt="" decoding="async" loading="lazy" src={backgroundJpg.src} />
					</picture>
				</div>
			</div>
		</section>
	);
};

export default SignupBar;
