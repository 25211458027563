import React from 'react';
import cx from 'classnames';
import { MDText } from 'i18n-react';
import Link from 'next/link';

import { Button, ChecklistIcon, GlitterIcon, TickChunkyIcon, VehicleFrontIcon } from '@motorway/mw-highway-code';
import { Hyperlink } from '@motorway/mw-highway-code/alpha';

import cypressIds from 'Components/cypress_ids.json';
import { triggerBrowseVehiclesButtonClicked, triggerSignUpNowLinkClicked } from 'Services/analytics/events';
import {
	triggerMotorwayMoveLinkClicked,
	triggerMotorwayPayLinkClicked,
} from 'Services/analytics/events/homePageEvents';
import { applyCypressData } from 'Utilities/helpers';
import useIsAuth from 'Utilities/hooks/useIsAuth';
import { useVehicleListButtonConfigWithUnauthedFallback } from 'Utilities/hooks/vehicleList/useVehicleListButtonConfig';

import MobileBreak from '../shared/MobileBreak/MobileBreak';
import texts from '../texts.json';

import heroAvif from './images/hero-vehicles.avif';
import heroPng from './images/hero-vehicles.png';
import heroWebp from './images/hero-vehicles.webp';

import styles from './Masthead.module.scss';

const LocalT = new MDText(texts);

const HomepageMasthead: React.FC = () => {
	const isAuth = useIsAuth();
	const { as, href } = useVehicleListButtonConfigWithUnauthedFallback({ unauthorizedFallback: 'signUp' });

	return (
		<>
			<div className={styles.heroOuterWrapper}>
				<div className={styles.heroWrapper}>
					<div className={cx(styles.hero)}>
						<div className={cx(styles.content)}>
							<h1>
								{LocalT.translate('homePage.hero.title', {
									strong: <strong>{LocalT.translate('homePage.hero.strong')}</strong>,
								})}
							</h1>
							<p>{LocalT.translate('homePage.hero.newDetail', { mobileBreak: <MobileBreak /> })}</p>
							<footer>
								<Link legacyBehavior passHref as={as} href={href}>
									<Button
										{...applyCypressData(cypressIds.buttons.hero)}
										label={LocalT.translate(`homePage.hero.anchorButtonLabel${isAuth ? ':signin' : ''}`)}
										onClick={() =>
											isAuth ? triggerBrowseVehiclesButtonClicked('page_top') : triggerSignUpNowLinkClicked('page_top')
										}
									/>
								</Link>
							</footer>
						</div>
					</div>
					<div className={styles.image}>
						<picture>
							<source srcSet={heroWebp.src} type="image/webp" />
							<source srcSet={heroAvif.src} type="image/avif" />
							<img
								alt="An example of a vehicle listing for a Volkswagen Golf"
								decoding="async"
								height="985"
								loading="lazy"
								src={heroPng.src}
								width="1945"
							/>
						</picture>
					</div>
					<svg
						height="0"
						style={{ position: 'absolute', visibility: 'hidden' }}
						version="1.1"
						width="0"
						xmlns="http://www.w3.org/2000/svg"
					>
						<defs>
							<filter id="heroFilter">
								<feGaussianBlur in="SourceGraphic" result="blur" stdDeviation="5"></feGaussianBlur>
								<feColorMatrix
									in="blur"
									mode="matrix"
									result="goo"
									values="1 0 0 0 0  0 1 0 0 0  0 0 1 0 0  0 0 0 25 -9"
								></feColorMatrix>
								<feComposite in="SourceGraphic" in2="goo" operator="atop"></feComposite>
							</filter>
						</defs>
					</svg>
				</div>
			</div>
			<ul className={styles.uspBlocks}>
				<li>
					<VehicleFrontIcon filled={false} size="large" />
					<h3>{LocalT.translate('homePage.hero.usp.point1.newTitle')}</h3>
					<p>{LocalT.translate('homePage.hero.usp.point1.newDetail')}</p>
				</li>
				<li>
					<TickChunkyIcon filled={false} size="large" />
					<h3>{LocalT.translate('homePage.hero.usp.point2.title')}</h3>
					<p>{LocalT.translate('homePage.hero.usp.point2.newDetail')}</p>
				</li>
				<li>
					<GlitterIcon filled={false} size="large" />
					<h3>{LocalT.translate('homePage.hero.usp.point3.newTitle')}</h3>
					<p>{LocalT.translate('homePage.hero.usp.point3.newDetail')}</p>
				</li>
				<li>
					<ChecklistIcon filled={false} size="large" />
					<h3>{LocalT.translate('homePage.hero.usp.point4.newTitle')}</h3>
					<p>
						{LocalT.translate('homePage.hero.usp.point4.newDetail', {
							motorwayMove: (
								<Hyperlink
									inherit
									as="a"
									href="/motorway-move"
									label={LocalT.translate('homePage.hero.usp.point4.motorwayMove')}
									onClick={() => triggerMotorwayMoveLinkClicked('page_top')}
									target="_blank"
								/>
							),
							motorwayPay: (
								<Hyperlink
									inherit
									as="a"
									href="/motorway-pay"
									label={LocalT.translate('homePage.hero.usp.point4.motorwayPay')}
									onClick={() => triggerMotorwayPayLinkClicked('page_top')}
									target="_blank"
								/>
							),
						})}
					</p>
				</li>
			</ul>
		</>
	);
};

export default HomepageMasthead;
