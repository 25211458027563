import React from 'react';

import { useBreakpoint } from 'Context/BreakpointsContext/BreakpointsContext';

import { useVideoModal } from './VideoModal/useVideoModal';
import { VideoModal } from './VideoModal/VideoModal';
import { VideoPoster } from './VideoPoster/VideoPoster';
import type { TestimonialVideoProps } from './NewTestimonials.types';

import styles from './Testimonials.module.scss';

export const TestimonialVideo: React.FC<TestimonialVideoProps> = ({ description, heading, youtubeVideoId }) => {
	const { closeModal: closeVideoModal, openModal: onClick, state: isVideoModalOpen } = useVideoModal();

	const {
		minWidth: { breakpointTablet: isDesktop },
	} = useBreakpoint();

	return (
		<>
			<section className={styles.testimonialVideo}>
				<VideoPoster
					alt={description}
					onClick={onClick}
					orientation={isDesktop ? 'all' : 'top'}
					showPlayButton={true}
					youtubeVideoId={youtubeVideoId}
				/>
				<div className={styles.text}>
					<h3>{heading}</h3>
					<p>{description}</p>
				</div>
			</section>
			{isVideoModalOpen && <VideoModal dismissModal={closeVideoModal} youtubeVideoId={youtubeVideoId} />}
		</>
	);
};
