import React, { useEffect } from 'react';
import cx from 'classnames';
import { MDText } from 'i18n-react';

import Content from 'Storybook/local/Content/Content';

import texts from '../texts.json';

import { TestimonialVideo } from './TestimonialVideo';

import styles from './Testimonials.module.scss';

const LocalT = new MDText(texts.homePage.testimonials);

const testimonialVideos = [
	{
		description: LocalT.translate('description1'),
		heading: LocalT.translate('heading1'),
		youtubeVideoId: 'D6sTad5aL5c',
	},
	{
		description: LocalT.translate('description2'),
		heading: LocalT.translate('heading2'),
		youtubeVideoId: '7q6ZTpOiAFk',
	},
];

const NewTestimonials = (): React.ReactElement => {
	const swiperInstanceRef = React.useRef<unknown>();

	useEffect(() => {
		const swiperRootClass = `.swiper-container.${styles.carousel}`;

		const initSwiper = async () => {
			const { Swiper } = await import(/* webpackChunkName: "swiper" */ 'swiper');
			const { Autoplay, Pagination } = await import(/* webpackChunkName: "swiper" */ 'swiper/modules');
			swiperInstanceRef.current = new Swiper(swiperRootClass, {
				breakpoints: {
					1120: {
						enabled: true,
						slidesPerView: 'auto',
					},
					1121: {
						enabled: false,
						slidesPerView: 2,
					},
				},
				centerInsufficientSlides: true,
				direction: 'horizontal',
				loop: false,
				modules: [Autoplay, Pagination],
				pagination: {
					clickable: true,
					el: `${swiperRootClass} .swiper-pagination`,
					type: 'bullets',
				},
				slidesPerView: 'auto',
				updateOnWindowResize: true,
				watchOverflow: true,
			});
		};

		initSwiper().catch((e) => {
			// eslint-disable-next-line no-console
			console.error(`An error occurred while loading the component; ${e}`);
		});
	}, []);

	return (
		<Content
			center
			className={styles.testimonials}
			element="section"
			id="testimonials"
			wrapper={{
				className: `${styles.testimonialsWrapper} ${styles.newTestimonials}`,
			}}
		>
			<section className={styles.testimonialContainer}>
				<div className={cx('swiper-container', styles.carousel)}>
					<div className={cx('swiper-wrapper', styles.carouselVideo)}>
						{testimonialVideos.map((video) => {
							const { description, heading, youtubeVideoId } = video;

							return (
								<figure key={youtubeVideoId} className={cx('swiper-slide', styles.video)}>
									<TestimonialVideo
										key={youtubeVideoId}
										description={description as string}
										heading={heading as string}
										youtubeVideoId={youtubeVideoId}
									/>
								</figure>
							);
						})}
					</div>
					<div className="swiper-pagination" />
				</div>
			</section>
		</Content>
	);
};

export default NewTestimonials;
